import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Про Нас | CleanIT Cleaning</title>
        <meta
          name={"description"}
          content={"щодня перевершуємо ваші очікування"}
        />
        <meta
          property={"og:title"}
          content={"Про Нас | CleanIT Cleaning"}
        />
        <meta
          property={"og:description"}
          content={"щодня перевершуємо ваші очікування"}
        />
        <meta
          property={"og:image"}
          content={"https://cornola-wer.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cornola-wer.com/img/045397.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cornola-wer.com/img/045397.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cornola-wer.com/img/045397.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cornola-wer.com/img/045397.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cornola-wer.com/img/045397.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cornola-wer.com/img/045397.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://cornola-wer.com/img/4.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="670px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h2" font="--headline2">
            Про Нас
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
          У CleanIT Cleaning Services ми докладаємо максимум зусиль, щоб гарантувати, що ваше робоче місце буде бездоганним і привабливим. Наш широкий спектр послуг розроблено для задоволення унікальних потреб кожного офісу, забезпечуючи чисте та здорове середовище для вашої команди. Відкрийте для себе виняткові послуги, які ми пропонуємо, і те, як вони можуть перетворити ваш офіс на незайману гавань продуктивності.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          flex-wrap="wrap"
        />
        <Box
          width="100%"
          margin="0px 0px 64px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--headline2"
            width="45%"
            lg-width="100%"
            lg-text-align="center"
          >
            Індивідуальні рішення для прибирання офісу
          </Text>
        </Box>
        <Box display="flex" width="100%" md-flex-wrap="wrap">
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            md-margin="0px 0px 40px 0px"
            margin="0px 3% 0px 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
             Ми розуміємо, що кожен офіс індивідуальний, тому ми пропонуємо індивідуальні рішення з прибирання відповідно до ваших потреб. Наша команда тісно співпрацює з вами, щоб розробити план прибирання, який відповідає вашим пріоритетам і графіку. 
            </Text>
          </Box>
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0 0px 0 0px"
          >
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              Від щоденного технічного обслуговування до періодичного глибокого чищення, CleanIT має гнучкість і досвід, щоб забезпечити виняткові результати.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-1"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="center"
          lg-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              src="https://cornola-wer.com/img/5.jpg"
              position="absolute"
              display="block"
              width="100%"
              left={0}
              min-height="100%"
              object-fit="cover"
              top={0}
              right={0}
              bottom={0}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          flex-direction="column"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            width="100%"
            height="auto"
            overflow-x="hidden"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
          >
            <Image
              bottom={0}
              src="https://cornola-wer.com/img/6.jpg"
              top={0}
              display="block"
              width="100%"
              left={0}
              right={0}
              min-height="100%"
              object-fit="cover"
              position="absolute"
            />
          </Box>
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            md-text-align="left"
          >
           Спеціалізовані бригади з прибирання
          </Text>
          <Text
            margin="5px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Наші спеціалізовані команди з прибирання навчені вирішувати різні аспекти чистоти офісу, гарантуючи, що кожен куточок вашого робочого простору буде бездоганним. З увагою до деталей і прагненням досконалості наші команди забезпечують:
            <br />
            <br />
            Загальне прибирання офісу: щоденне або щотижневе прибирання для підтримки чистоти та організованості робочого простору, включаючи прибирання пилу, пилососом і вивезення сміття.
            <br />
            <br />
            Дезінфекція туалетів: ретельне прибирання та санітарна обробка туалетів для забезпечення гігієни та комфорту для ваших співробітників і відвідувачів.
            <br />
            <br />
            Прибирання кімнати відпочинку: підтримуйте свою кімнату відпочинку чистою та затишною за допомогою регулярного прибирання поверхонь, приладів і підлоги.

          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
